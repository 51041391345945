<script lang="ts">
  import { getContext } from 'svelte'
  import { state } from '../../../store/state'

  import ContentDataRenderer from '../content-data-renderer/content-data-renderer.svelte'
  import { Presenter } from '../presenter'

  import CellRenderer from './content-cell-renderer.svelte'
  import CardContainer from '../main-presenter/main-card-container.svelte'

  export let containerHeight: number

  const { showCardView, showPreview } = getContext(Presenter.key) as any
</script>

<ContentDataRenderer
  cellRenderer={CellRenderer}
  cardContainer={CardContainer}
  contentKind={state.contents}
  {containerHeight}
  {showCardView}
  {showPreview}
/>
