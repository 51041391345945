<script lang="ts">
  import { onMount } from 'svelte'
  import { type Writable, writable, get } from 'svelte/store'

  import { state } from '../../../store/state'
  import { AnyContentKind } from '../../../store/kind'

  import ViewToolbarButtons from '../../atoms/view-toolbar-buttons.svelte'

  import ContentPreview from '../../content-view/content-preview.svelte'

  import { Presenter, toggle } from '../presenter'
  import AnyContentPresenter from '../presenter.svelte'

  import DataRenderer from './content-data-renderer.svelte'

  let showCardView = writable(false)
  let showPreview = writable(true)

  const { contents } = state
  const { data } = contents
  const toggles = { showCardView, showPreview }
  const subscriptions: (() => void)[] = []

  let routeParams = writable({} as any)
  let content: any
  let presenter: Presenter
  let presenterUnsubscribe: () => void

  const handleCardView = (view: 'grid' | 'list') => {
    const isCardView = view === 'grid'
    if ($showCardView !== isCardView) {
      toggle('showCardView', toggles)
    }
  }

  const setContent = () => {
    if (!presenter) {
      return
    }

    const id = presenter.selectedContentId.get()
    const newContent = data.get().find((a) => {
      return a.id === id
    })

    if (newContent) {
      content = { ...newContent }
    }
  }

  subscriptions.push(
    data.subscribe(() => {
      setContent()
    })
  )

  subscriptions.push(
    routeParams.subscribe((params) => {
      Object.keys(toggles).forEach((key) => {
        const toggle: Writable<boolean> = (toggles as any)[key]
        const external = params[key]
        if (external === undefined) {
          return
        }

        if (external.toString() !== get(toggle).toString()) {
          const booleanValue = external === 'true'
          toggle.set(booleanValue)
        }
      })
    })
  )
  $: {
    if (presenter) {
      if (presenterUnsubscribe) {
        presenterUnsubscribe()
      }

      presenterUnsubscribe = presenter.selectedContentId.listen(() => {
        setContent()
      })
    }
  }

  onMount(() => {
    return () => {
      subscriptions.forEach((sub) => sub())
      if (presenterUnsubscribe) {
        presenterUnsubscribe()
      }
    }
  })
</script>

<div class="search-presenter hide-columns h-full w-full">
  <AnyContentPresenter
    bind:presenter
    contentKindName={AnyContentKind.nameOnState}
    {DataRenderer}
    {routeParams}
    {showCardView}
    {showPreview}
  >
    <div slot="toolbar-card-view">
      <ViewToolbarButtons
        showCardView={$showCardView}
        showPreview={$showPreview}
        onToggleCardView={handleCardView}
        onTogglePreview={() => toggle('showPreview', toggles)}
      />
    </div>
    <ContentPreview
      slot="right-panel"
      {content}
      contentKind={contents}
      highlightFields={['id', ...contents.kind.ftsFields]}
      noContentText={'Select a content'}
      bind:showPreview={$showPreview}
    />
  </AnyContentPresenter>
</div>

<style lang="postcss">
  /* hiding byline when < 60rem */
  @media (max-width: 60rem) {
    .hide-columns :global(thead > tr > th:nth-child(5)),
    .hide-columns :global(tbody > tr > td:nth-child(5)) {
      display: none;
    }
  }

  /* TODO:[UNIMPLEMENTED]: remove next line when implement liveblogs */
  .search-presenter :global(div[aria-labelledby='checkbox-6']) {
    display: none;
  }
</style>
